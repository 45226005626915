import React, { useEffect } from 'react'
import Sec1 from './Sec1'
import Sec2 from './Sec2'
import Sec3 from './Sec3'
import Sec4 from './Sec4'
import Sec5 from './Sec5'
import Sec6 from './Sec6'
import Sec7 from './Sec7'
import Sec8 from './Sec8'
import Sec10 from './Sec10'
import Sec11 from './Sec11'
import Sec14 from './Sec14'
import Sec9 from './Sec9'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Testimonial from '../../CommonPages/HireIndianTalent/HireDeveloper/HireShopify/Testimonial'
import { Helmet } from 'react-helmet'




function RotoScoping() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Customize duration as needed
  }, []);
  return (
    <div>
      <Helmet>

        <title> Offshore Rotoscoping Services In India | Rotoscoping Services </title>

        <meta name="description" content="Professional Offshore Rotoscoping Services in India: Precise, frame-by-frame animation for flawless VFX. Enhance your projects with expert rotoscoping services." />
      
      </Helmet>
        <Sec1/>
        <Sec2/>
        <Sec3/>
        <Sec5/>
        <Testimonial/>
        <Sec4/>
        <Sec6/>
        <Sec7/>
        <Sec8/>
        <Sec9/>
        <Sec10/>
        <Sec11/>
  
        <Sec14/>
        
    </div>
  )
}

export default RotoScoping